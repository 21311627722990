<template>
    <slot name="header" />

    <div class="ml-auto mr-auto w-full font-inter">
        <div class="lg:max-w-7xl md:w-full relative ml-auto mr-auto p-2">
            <slot name="body" />
        </div>
    </div>
</template>

<script setup lang="ts"></script>
