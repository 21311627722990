import { PaymentTier } from '@/api/interfaces/payment-tier.api';
import { EscrowAccountTitleCompanyExtended, FlatWireDoc } from '@/interfaces/payment-settings/escrow-account';
import { defineStore } from 'pinia';
import { ref } from 'vue';

/** Provides a source of truth for the payment settings page features */
export const useEmployeeAccountPaymentSettingsStore = defineStore(
    'employeeAccountPaymentSettings',
    () => {
        const paymentTiers = ref<PaymentTier[]>([]);

        const escrowAccounts = ref<EscrowAccountTitleCompanyExtended[]>([]);

        const selectedWireDocs = ref<FlatWireDoc[]>([]);

        function setPaymentTiers(companyTiers: PaymentTier[] = []) {
            paymentTiers.value = companyTiers;
        }

        function setEscrowAccounts(accounts: EscrowAccountTitleCompanyExtended[] = []) {
            escrowAccounts.value = accounts;
        }

        function selectWireDocs(incomingSelectedDocs: FlatWireDoc[]) {
            const merged = [...selectedWireDocs.value, ...incomingSelectedDocs];
            const deduplicated = new Map(merged.map(doc => [doc.id, doc]));
            selectedWireDocs.value = Array.from(deduplicated.values()) || [];
        }
        function deselectWireDocs(incomingDeselectedDocs: FlatWireDoc[]) {
            const ids = new Set(incomingDeselectedDocs.map(d => d.id));
            selectedWireDocs.value = [...selectedWireDocs.value].filter(doc => !ids.has(doc.id));
        }

        return {
            paymentTiers,
            setPaymentTiers,
            escrowAccounts,
            setEscrowAccounts,
            selectedWireDocs,
            selectWireDocs,
            deselectWireDocs,
        };
    },
    {
        persist: false,
    },
);
