/** Determines if the url is the current page **/
function isCurrentUrl(url: string) {
    if (!url) {
        return false;
    }
    const fullUrl = window.location.href.toLowerCase();
    if (fullUrl.includes((url as string)?.toLowerCase())) {
        return true;
    }
}
/** passes 'active-menu-item' class to active menu item **/
export function activeMenuItem(pathOrUrls: string[] | undefined): string {
    return (pathOrUrls as string[]).some(url => isCurrentUrl(url)) ? 'active-menu-item' : '';
}
