<script lang="ts" setup>
import Button from 'primevue/button';
import { ref } from 'vue';
import { useForm } from 'vee-validate';
import { DisablePaymentSubmitPayload } from './form-payloads';
import { FlatWireDoc } from '@/interfaces/payment-settings/escrow-account';

const props = defineProps<{ flatWireDoc?: FlatWireDoc }>();
const emits = defineEmits<{
    (event: 'submit', payload: DisablePaymentSubmitPayload): void;
    (event: 'cancel'): void;
}>();
const { defineField, handleSubmit } = useForm();

const [note] = defineField('note');
const isSubmitAttemptedWithEmptyText = ref(false);

const onSubmit = handleSubmit(values => {
    const hasNoteContent = !!values.note.trim(); // returns true if trimming results in non empty
    isSubmitAttemptedWithEmptyText.value = hasNoteContent;
    if (!hasNoteContent) {
        return;
    }
    emits('submit', { flatWireDoc: props.flatWireDoc, note: values.note });
});

const onCancel = () => {
    emits('cancel');
};

const onInput = () => {
    if (isSubmitAttemptedWithEmptyText.value) {
        isSubmitAttemptedWithEmptyText.value = false; // Reset error state when user starts typing
    }
};
</script>

<template>
    <form @submit="onSubmit">
        <h3 class="text-center font-semibold">Disable Payment</h3>
        <div class="text-sm font-normal text-gray-400 mb-9 text-center">Are you sure you want to disable this payment?</div>
        <div class="flex flex-col gap-8 font-medium">
            <div class="flex flex-col gap-1">
                <div
                    v-if="isSubmitAttemptedWithEmptyText"
                    class="text-red-500"
                >
                    Notes for disabling payment account cannot be empty.
                </div>
                <label class="text-sm font-normal">Explanation:</label>
                <textarea
                    id="note"
                    v-model="note"
                    label="note"
                    @input="onInput"
                    placeholder="Why are you disabling this payment?"
                    class="rounded"
                ></textarea>
            </div>
            <div class="flex flex-col gap-1">
                <Button
                    label="Disable Payments"
                    severity="danger"
                    type="submit"
                    :disabled="!note"
                ></Button>
                <Button
                    outlined
                    label="Cancel"
                    type="button"
                    @click="onCancel"
                ></Button>
            </div>
        </div>
    </form>
</template>
