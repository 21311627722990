export type ParsedGoogleMapsPlace = {
    streetNumber: string;
    streetName: string;
    city: string;
    county: string;
    stateShort: string;
    stateLong: string;
    countryShort: string;
    countryLong: string;
    zipCode: string;
    addressLineOne: string;
};
export function parseGoogleMapsPlace(place: google.maps.places.PlaceResult): ParsedGoogleMapsPlace {
    place = place || {};

    const byType = (place.address_components || []).reduce(function (
        acc: Record<string, google.maps.GeocoderAddressComponent>,
        data: google.maps.GeocoderAddressComponent,
    ) {
        data.types.forEach(function (type) {
            acc[type] = data;
        });
        return acc;
    }, {});

    const result = {
        streetNumber: placeGet('street_number'),
        streetName: placeGet('route'),
        city:
            placeGet('locality') ||
            placeGet('sublocality') ||
            placeGet('sublocality_level_1') ||
            placeGet('neighborhood') ||
            placeGet('administrative_area_level_3') ||
            placeGet('administrative_area_level_2'),
        county: placeGet('administrative_area_level_2'),
        stateShort: placeGet('administrative_area_level_1', true),
        stateLong: placeGet('administrative_area_level_1'),
        countryShort: placeGet('country', true),
        countryLong: placeGet('country'),
        zipCode: placeGet('postal_code'),
        addressLineOne: '',
    };

    result.addressLineOne = [result.streetNumber, result.streetName].filter(Boolean).join(' ');

    return result;

    function placeGet(key: string, short?: boolean): string {
        if (!(key in byType)) return '';
        return (short ? byType[key].short_name : byType[key].long_name) || '';
    }
}
