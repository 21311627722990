<template>
    <Dialog
        :visible="confirmDialogOpen"
        header=" "
        modal
        dismissableMask
        :closable="false"
        :draggable="false"
        :style="{ width: '500px', 'max-width': '672px' }"
    >
        <div class="flex flex-col p-6">
            <h3 class="text-center font-semibold">Confirm Changes</h3>
            <div class="text-sm font-normal text-gray-400 mb-9 text-center">You’ve made edits to the payment tiers.</div>
            <div class="flex flex-col gap-2 font-medium">
                <Button
                    @click="saveAccountPaymentTiers"
                    label="Save Changes"
                ></Button>
                <Button
                    @click="confirmCancelTierEdit"
                    outlined
                    label="Cancel"
                ></Button>
            </div>
        </div>
    </Dialog>
</template>

<script lang="ts" setup>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';

const props = defineProps<{ hasDirtyState: boolean; isOpen: boolean }>();
const confirmDialogOpen = ref(false);

watch([props], ([newProps]) => (confirmDialogOpen.value = newProps.isOpen));

const emits = defineEmits(['saveAccountPaymentTiers', 'confirmCancelTierEdit']);
function saveAccountPaymentTiers() {
    emits('saveAccountPaymentTiers');
}
function confirmCancelTierEdit() {
    emits('confirmCancelTierEdit');
}

function confirmStayInDirtyForm() {
    confirmDialogOpen.value = true;
    return confirmDialogOpen;
}

function beforeWindowUnload(e: Event) {
    if (props.hasDirtyState) {
        e.preventDefault();
        e.returnValue = false;
    }
}
onBeforeMount(() => {
    window.addEventListener('beforeunload', beforeWindowUnload);
});
onBeforeUnmount(() => {
    window.removeEventListener('beforeunload', beforeWindowUnload);
});

onBeforeRouteLeave(async (to, from, next) => {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (await confirmStayInDirtyForm()) {
        next(false);
    } else {
        // Navigate to next view
        next();
    }
});
</script>
