import axios from 'axios';
import PrimeVue from 'primevue/config';
import { App, createApp } from 'vue/dist/vue.esm-bundler';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faR, fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { createHead } from '@unhead/vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedState from 'pinia-plugin-persistedstate';
import Ripple from 'primevue/ripple';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';

import { allComponents } from './components';
import { ClosingLockDesignSystem } from './design-system';
import { useCurrentUserService } from './services/current-user.service';
import RootSpaApp from './spa/App.vue';
import { buildEmployeePortalRouter } from './spa/routes';
library.add(fas, faR);

axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': window.csrf ?? '',
};

/** Initializes an SPA Vue app */
export async function mount(elementSelector: string) {
    const app = createApp(RootSpaApp);
    app.use(buildEmployeePortalRouter('/spa'));
    decorate(app);
    app.component('font-awesome-icon', FontAwesomeIcon);
    app.mount(elementSelector);
    const currentUserService = useCurrentUserService();
    await currentUserService.loadGlobalUserOrLogout();
}

/** Applies to an externally created vue app the libraries and components known to the module */
export function decorate(app: App, addComponents = false) {
    const pinia = createPinia();
    pinia.use(piniaPluginPersistedState);
    app.use(pinia);
    app.use(createHead());

    if (addComponents) {
        for (const componentName of Object.keys(allComponents)) {
            const castedName = componentName as keyof typeof allComponents;
            app.component(componentName, allComponents[castedName]);
        }
        app.component('font-awesome-icon', FontAwesomeIcon);
    }

    app.use(PrimeVue, {
        unstyled: true,
        pt: ClosingLockDesignSystem,
        ptOptions: { mergeProps: true, mergeSections: true },
        ripple: true,
    });
    app.use(ToastService);
    app.directive('ripple', Ripple);
    app.directive('tooltip', Tooltip);
    app.config.globalProperties.$http = axios;
    const currentUserService = useCurrentUserService();
    currentUserService.loadGlobalUserOrLogout();
}
