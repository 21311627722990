import { useEmployeeApi } from '@/api/client/api-client';
import { CompanyAccount } from '@/api/interfaces/company-account.api';
import { useCompanyAccountStore } from '@/stores/shared/account.store';

export function useCompanyAccountService() {
    const apiClient = useEmployeeApi();
    const accountStore = useCompanyAccountStore();

    async function fetchAccounts() {
        const { data, error } = await apiClient('/api/accounts').get().json<CompanyAccount[]>();
        if (error.value) {
            throw new Error('Error fetching account list' + error.value);
        }
        if (data.value) {
            accountStore.companyAccountList;
        }
        return data;
    }

    async function getAccountById(id: CompanyAccount['id']): Promise<CompanyAccount | null> {
        const { data, error } = await apiClient(`/api/accounts/${id}`).get().json<CompanyAccount>();
        if (error.value) {
            throw new Error('Error getting account by id' + error.value);
        }
        return data.value;
    }

    return {
        fetchAccounts,
        getAccountById,
        get companyAccountsList() {
            return accountStore.companyAccountList;
        },
    };
}
