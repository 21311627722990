// axios.defaults.baseURL = process.env.VUE_APP_API_URL;
import { csrfToken } from '@/components/Utils/Helper';
import axios from 'axios';
import toastr from 'toastr';
import { App } from 'vue';

/** TODO: remove toast calls from this client. An http client should have no say in how errors are presented */
export function useHttpClient(
    contentType = 'application/json',
    { suppressErrorToast = false }: { suppressErrorToast?: boolean } = {},
) {
    const httpClient = axios.create();

    httpClient.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken.value;

    if (contentType) {
        httpClient.defaults.headers.common['Content-Type'] = contentType;
    }

    httpClient.interceptors.response.use(
        response => {
            // TODO: this interceptor breaks the type system.
            // remove it when dependent components are all in TS
            // and we know where to adjust
            return response.data;
        },
        error => {
            let message = error.message;
            if (error.response?.data) {
                if (error.response.data.errors) {
                    message = '';
                } else if (error.response.data.error) {
                    message = error.response.data.error;
                } else if (error.response.data.message) {
                    message = error.response.data.message;
                }
            }

            if (message && !suppressErrorToast && error.response.data.suppress_toast !== true) {
                toastr.error(message, '', { timeOut: 5000 });
            }

            return Promise.reject(error);
        },
    );

    return httpClient;
}

export const httpClient = useHttpClient();

export default function (app: App) {
    // this is probably an undue attribution of foreign properties to the vue instance.
    // TODO: evaluate how to make these properties available (mixin? inject? )
    app.axios = axios;
    app.$http = axios;

    app.config.globalProperties.axios = axios;
    app.config.globalProperties.$http = axios;
}
