import { PrimeVuePTOptions } from 'primevue/config';
import { DropdownContext, DropdownProps, DropdownState } from 'primevue/dropdown';

export const DropdownDesign = {
    dropdown: {
        root: { class: 'w-full md:w-14rem rounded' },
        item: ({ context }: { props: DropdownProps; state: DropdownState; context: DropdownContext }) => ({
            class: context.selected ? 'bg-normal-600' : context.focused ? 'bg-normal-800' : undefined,
        }),
        trigger: '',
        input: '',
    },
} as Pick<PrimeVuePTOptions, 'dropdown'>;
