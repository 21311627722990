import { CompanyAccount } from '@/api/interfaces/company-account.api';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useCompanyAccountStore = defineStore(
    'currentAccount',
    () => {
        const companyAccountList = ref<CompanyAccount[]>([]);
        function setAccountList(list?: CompanyAccount[]) {
            companyAccountList.value = list || [];
        }
        return { setAccountList, companyAccountList };
    },
    { persist: true },
);
