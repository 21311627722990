import { useEmployeeApi } from '@/api/client/api-client';
import { CompanyAccount } from '@/api/interfaces/company-account.api';
// prettier-ignore
import {
  EscrowAccountBranch,
  EscrowAccountTitleCompany,
  EscrowAccountWireDoc,
  UpdateEscrowAccountBankingPayload,
  UpdateEscrowAccountBranchPayload,
} from '@/api/interfaces/escrow-account.api';
// prettier-ignore
import {
  EscrowAccountTitleCompanyExtended,
  EscrowAccountWireDocExtended,
  EscrowAccountWireStatus,
  FlatWireDoc
} from '@/interfaces/payment-settings/escrow-account';
import { RequestUnknownError } from '@/shared/error/request-error.error';
import { API_DATE_FORMAT } from '@/shared/utils/format-date';
import { useEmployeeAccountPaymentSettingsStore } from '@/stores/employee/payment-settings.store';
import { formatDate } from '@vueuse/core';
import dayjs from 'dayjs';
import { ref } from 'vue';

export function useEscrowAccountService() {
    const apiClient = useEmployeeApi();
    const paymentSettingsStore = useEmployeeAccountPaymentSettingsStore();

    const chosenStartDate = ref<Date>();
    const disableNote = ref<string>('');
    const suggestedStartDate = ref<Date>();
    const bulkConfirmationPending = ref(false);
    const intendedBulkTargetStatus = ref<EscrowAccountWireDocExtended['status']>();
    const bulkStatusChangeErrors = ref<string[]>([]);
    const bulkCompanyId = ref<CompanyAccount['id']>();

    async function loadAccountEscrowAccounts(accountId: CompanyAccount['id']) {
        paymentSettingsStore.setEscrowAccounts([]);
        const { data, error } = await apiClient(`/api/payments/escrowaccounts/${accountId}`)
            .get()
            .json<EscrowAccountTitleCompany[]>();
        if (error.value) {
            return console.error(new Error('Error fetching escrow accounts for account ' + accountId + error));
        }
        if (data.value) {
            // Transforms the data, applying the status and normalizing fields
            const accountsWithStatus: EscrowAccountTitleCompanyExtended[] = data.value.map(title => ({
                ...title,
                branches:
                    title?.branches?.map(branch => ({
                        ...branch,
                        wire_docs: branch?.wire_docs?.map(doc => applyWireDocStatus(branch, doc)) ?? [],
                    })) ?? [],
            }));
            paymentSettingsStore.setEscrowAccounts(accountsWithStatus);
        }
        return paymentSettingsStore.escrowAccounts;
    }

    async function updateBranchInformation(
        accountId: CompanyAccount['id'],
        branchId: EscrowAccountBranch['id'],
        branchInformation: UpdateEscrowAccountBranchPayload,
    ) {
        const { error } = await apiClient(`/api/branches/${branchId}`).put({ ...branchInformation });
        if (error.value) {
            // throw new RequestUnknownError('Error trying to change branch information', { branchInformation, error: error.value });
        }
        await loadAccountEscrowAccounts(accountId);
    }

    async function updateBankingInformation(
        accountId: CompanyAccount['id'],
        bankingInformation: UpdateEscrowAccountBankingPayload,
    ) {
        const { error } = await apiClient(`/api/payments/savewiredocinfo/${bankingInformation.wire_doc_id}`).post(
            bankingInformation,
        );
        if (error.value) {
            throw new RequestUnknownError('Error trying to change wire doc information information', {
                bankingInformation,
                error: error.value,
            });
        }
        await loadAccountEscrowAccounts(accountId);
    }

    async function updateEnablePayment({
        accountId,
        enableDocs,
        startDate,
        wireDocIds,
        note,
    }: {
        accountId: CompanyAccount['id'];
        enableDocs: boolean;
        startDate?: Date;
        wireDocIds: EscrowAccountWireDoc['id'][];
        note?: string;
    }) {
        if (!wireDocIds) {
            return;
        }
        const path = enableDocs ? '/api/payments/enable' : '/api/payments/disable';
        const payload = {
            wire_doc_ids: wireDocIds,
            note: !enableDocs ? note : undefined,
            payment_start_date: enableDocs && startDate ? formatDate(startDate, API_DATE_FORMAT) : null,
        };

        const { error } = await apiClient(path).post(payload);
        if (error.value) {
            throw new RequestUnknownError('Error trying to change wire doc information information', {
                enableDocs,
                wireDocIds,
                error: error.value,
            });
        }
        await loadAccountEscrowAccounts(accountId);
    }

    function isBankingComplete(wireDoc: EscrowAccountWireDoc): boolean {
        return !!wireDoc.account_number && !!wireDoc.routing_number;
    }
    function isAddressComplete(branchAddress: EscrowAccountBranch['address']): boolean {
        if (!branchAddress) return false;
        return !!(branchAddress?.address_line_1 && branchAddress?.city && branchAddress?.state && branchAddress?.zip);
    }

    function applyWireDocStatus(branch: EscrowAccountBranch, wireDoc: EscrowAccountWireDoc): EscrowAccountWireDocExtended {
        const { DISABLED, ENABLED, PENDING, LOCKED, UNKNOWN } = EscrowAccountWireStatus;
        const fallbackStatus: EscrowAccountWireStatus = UNKNOWN;

        const mapKey = ({ dateIsFuture, paymentEnabled }: Record<string, boolean>) => [dateIsFuture, paymentEnabled].toString();
        const statusMapping = new Map(
            Object.entries({
                [mapKey({ dateIsFuture: true, paymentEnabled: true })]: PENDING,
                [mapKey({ dateIsFuture: false, paymentEnabled: true })]: ENABLED,
                [mapKey({ dateIsFuture: true, paymentEnabled: false })]: DISABLED,
                [mapKey({ dateIsFuture: false, paymentEnabled: false })]: DISABLED,
            }),
        );

        const paymentStartDate = wireDoc.payment_start_date ? dayjs(wireDoc.payment_start_date).toDate() : null;
        const dateIsFuture = !!paymentStartDate && paymentStartDate.getTime() > new Date().getTime();
        const paymentEnabled = wireDoc.enable_for_closinglock_payments;
        const status =
            isAddressComplete(branch.address) && isBankingComplete(wireDoc)
                ? statusMapping.get(mapKey({ dateIsFuture, paymentEnabled }))
                : LOCKED;
        return {
            ...wireDoc,
            status: status || fallbackStatus,
            payment_start_date: paymentStartDate,
        };
    }

    function setChosenStartDate(date: Date) {
        chosenStartDate.value = date;
        bulkConfirmationPending.value = false;
        applyBulkStatusChange();
    }

    function bulkDisablePayment(companyId: CompanyAccount['id'], noteSubmit: string) {
        bulkCompanyId.value = companyId;
        bulkConfirmationPending.value = false;
        disableNote.value = noteSubmit;
        applyBulkStatusChange();
    }

    function getSelectedItemsStatusEligibility(intendedStatus: EscrowAccountWireStatus) {
        const { selectedWireDocs } = paymentSettingsStore;
        const itemsAlreadyInStatus = selectedWireDocs.filter(
            doc => doc.status === intendedStatus && dayjs(doc.payment_start_date).isSame(chosenStartDate.value?.getTime(), 'day'),
        );
        const eligibleItemsByStatus = selectedWireDocs.filter(doc => !itemsAlreadyInStatus.find(item => item.id === doc.id));
        return {
            selectedWireDocs,
            itemsAlreadyInStatus,
            eligibleItemsByStatus,
        };
    }

    function findEarliestFutureDate(dates: Date[]) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const futureDates = dates.filter(date => new Date(date) > today);
        return futureDates.length ? new Date(Math.min(...futureDates.map(date => new Date(date).getTime()))) : new Date();
    }

    function setSuggestedDateFromSelection(targetStatus: EscrowAccountWireDocExtended['status']) {
        const { ENABLED, PENDING } = EscrowAccountWireStatus;
        suggestedStartDate.value = new Date();
        if (targetStatus === ENABLED) {
            const { eligibleItemsByStatus, selectedWireDocs } = getSelectedItemsStatusEligibility(targetStatus);
            // if there are enabled items selected, use today
            if (selectedWireDocs.find(doc => doc.status === ENABLED)) {
                suggestedStartDate.value = new Date();
            } else {
                const pendingStatusItemsDates = eligibleItemsByStatus
                    .filter(doc => doc.status === PENDING)
                    .map(doc => doc.payment_start_date || new Date());
                suggestedStartDate.value = findEarliestFutureDate(pendingStatusItemsDates) || new Date();
            }
        }
    }

    function initiateBulkStatusChange(companyId: CompanyAccount['id'], targetStatus: EscrowAccountWireDocExtended['status']) {
        intendedBulkTargetStatus.value = targetStatus;
        bulkCompanyId.value = companyId;
        // check if need to ask for a date
        bulkConfirmationPending.value = true;
        setSuggestedDateFromSelection(targetStatus);
    }

    function addBulkErrors(errors: string[] = []) {
        if (!errors) return;
        bulkStatusChangeErrors.value.push(...errors);
    }

    function errorMessageDocIdentifier(doc: FlatWireDoc) {
        return `Branch <b>"${doc.branchName}"</b>
          Wire Instruction <b>"${doc.name}</b>"`;
    }

    async function applyBulkStatusChange() {
        if (!bulkCompanyId.value) {
            console.error('No company id currently selected for bulk actions');
            return;
        }
        const { ENABLED } = EscrowAccountWireStatus;
        bulkStatusChangeErrors.value = [];
        const intendedStatus = intendedBulkTargetStatus.value;

        if (!intendedStatus) {
            console.error('No intended status previously set. Aborting bulk change');
            return;
        }

        const { eligibleItemsByStatus, itemsAlreadyInStatus } = getSelectedItemsStatusEligibility(intendedStatus);

        addBulkErrors(
            itemsAlreadyInStatus.map(
                doc =>
                    `${errorMessageDocIdentifier(doc)} was not changed because it
                    was already ${intendedStatus?.toLocaleLowerCase()}`,
            ),
        );

        if (intendedStatus === ENABLED) {
            const itemsWithIncompleteAddress = eligibleItemsByStatus.filter(doc => !isAddressComplete(doc.address));
            addBulkErrors(
                itemsWithIncompleteAddress.map(
                    doc => `${errorMessageDocIdentifier(doc)} cant be changed since it's address is incomplete`,
                ),
            );
        }

        const eligibleItems =
            intendedStatus === ENABLED
                ? eligibleItemsByStatus.filter(doc => isAddressComplete(doc.address))
                : eligibleItemsByStatus;

        if (!eligibleItems.length) {
            return;
        }

        await updateEnablePayment({
            accountId: bulkCompanyId.value,
            enableDocs: intendedStatus === ENABLED,
            startDate: chosenStartDate.value,
            wireDocIds: eligibleItems.map(doc => doc.id),
            note: '(Payment Disabled) ' + disableNote.value,
        });
        resetBulkChangeState();
    }

    async function updateWireDocNotes(accountId: CompanyAccount['id'], id: string, notes: string) {
        const path = `/api/payments/savewiredocinfo/${id}`;
        const { error } = await apiClient(path).post({ notes: notes });
        if (error.value) {
            throw new RequestUnknownError('Error trying to change wire doc notes', {
                notes: notes,
                error: error.value,
            });
        }
        await loadAccountEscrowAccounts(accountId);
    }

    function cancelBulkStatusChange() {
        bulkConfirmationPending.value = false;
        chosenStartDate.value = undefined;
    }

    function resetBulkChangeState() {
        cancelBulkStatusChange();
        intendedBulkTargetStatus.value = undefined;
        paymentSettingsStore.deselectWireDocs(paymentSettingsStore.selectedWireDocs);
    }

    return {
        isAddressComplete,
        loadAccountEscrowAccounts,
        get escrowAccounts(): EscrowAccountTitleCompanyExtended[] {
            return paymentSettingsStore.escrowAccounts;
        },
        bulkConfirmationPending,
        intendedBulkTargetStatus,
        initiateBulkStatusChange,
        get suggestedStartDate() {
            return suggestedStartDate.value;
        },
        setChosenStartDate,
        bulkDisablePayment,
        updateWireDocNotes,
        cancelBulkStatusChange,
        get bulkStatusChangeErrors() {
            return bulkStatusChangeErrors.value;
        },
        updateBranchInformation,
        updateBankingInformation,
        get selectedWireDocs() {
            return paymentSettingsStore.selectedWireDocs;
        },
        selectWireDocs: (docs: FlatWireDoc[]) => paymentSettingsStore.selectWireDocs(docs),
        deselectWireDocs: (docs: FlatWireDoc[]) => paymentSettingsStore.deselectWireDocs(docs),
    };
}
