import * as VueRouter from 'vue-router';

import FileSideNav from '@/components/Navigation/side-nav/SideNavMenu.vue';
import FileTabNav from '@/components/Navigation/tab-nav/FileTabNav.vue';
import PayoffsTabNav from '@/components/Navigation/tab-nav/PayoffsTabNav.vue';
import SettingsTabNav from '@/components/Navigation/tab-nav/SettingsTabNav.vue';
import UpdatePasswordTabNav from '@/components/Navigation/tab-nav/UpdatePasswordTabNav.vue';
import { SideNavSection } from '@/interfaces/navigation/side-nav';
import CompanyPaymentSettings from './../employee-portal/pages/accounts/payment-settings/AccountPaymentSettings.vue';
import AppVue from './App.vue';

// 1- if we absorb other portals, we only need to adjust the routing to consider the domain
// 2- add specialized router configs as their own const and merge them in the resulting router instance
const EMPLOYEE_ROUTES = [
    {
        path: '/',
        beforeEnter() {
            location.href = '/';
        },
        component: AppVue, // dummy config
    },
    {
        path: '/account/:accountId/payment-settings',
        component: CompanyPaymentSettings,
    },
];

const COMPANY_ROUTES = [
    {
        path: '/user/home',
        components: {
            sideNav: FileSideNav,
            tabNav: FileTabNav,
        },
        meta: {
            sideNavSection: SideNavSection.File,
        },
    },
    {
        path: '/user/manage',
        components: {
            sideNav: FileSideNav,
            tabNav: SettingsTabNav,
        },
        meta: {
            sideNavSection: SideNavSection.Settings,
        },
    },
    {
        path: '/user/payoffs',
        components: {
            sideNav: FileSideNav,
            tabNav: PayoffsTabNav,
        },
        meta: {
            sideNavSection: SideNavSection.Payoffs,
        },
    },
    {
        path: '/user/payoffs/view/:id',
        components: {
            sideNav: FileSideNav,
            tabNav: PayoffsTabNav,
        },
        meta: {
            sideNavSection: SideNavSection.Payoffs,
        },
    },
    {
        path: '/user/postclose',
        components: {
            sideNav: FileSideNav,
            tabNav: FileTabNav,
        },
        meta: {
            sideNavSection: SideNavSection.File,
        },
    },
    {
        path: '/user/settings',
        components: {
            sideNav: FileSideNav,
            tabNav: SettingsTabNav,
        },
        meta: {
            sideNavSection: SideNavSection.Settings,
        },
    },
    {
        path: '/user/password',
        components: {
            sideNav: FileSideNav,
            tabNav: UpdatePasswordTabNav,
        },
    },
];

export const buildEmployeePortalRouter = (basePath?: string) => {
    return VueRouter.createRouter({
        history: VueRouter.createWebHistory(basePath),
        routes: [...EMPLOYEE_ROUTES],
    });
};
export const buildCompanyPortalRouter = (basePath?: string) => {
    return VueRouter.createRouter({
        history: VueRouter.createWebHistory(basePath),
        routes: [...COMPANY_ROUTES],
    });
};
