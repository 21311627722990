<template>
    <ul class="flex flex-col font-inter flex-1 p-2">
        <li
            class="flex"
            v-for="option in menuOptionRouted"
            :key="option.label"
            :class="{ 'mt-auto': option.separator }"
        >
            <template v-if="!option?.separator">
                <a
                    class="rounded-md p-[10px] w-full flex flex-row min-w-fit"
                    :class="{
                        'bg-primary-100 text-primary-800-brand': option.active,
                        'text-neutral-400 hover:text-neutral-500': !option.active,
                        'gap-[10px]': expanded,
                    }"
                    :href="option.url"
                    @mouseenter="setHoverActive(option, true)"
                    @mouseleave="setHoverActive(option, false)"
                    v-ripple
                    v-tooltip.right="{
                        value: option.label,
                        disabled: expanded,
                        fitContent: true,
                        pt: {
                            root: { class: 'p-0' },
                            arrow: { class: 'hidden' },
                            text: { class: 'px-2 py-1 text-sm leading-1 font-inter' },
                        } as TooltipDirectivePassThroughOptions,
                    }"
                >
                    <component
                        :is="option.icon"
                        :active="option.active"
                        :width="28"
                        :height="28"
                        :style="{ opacity: hoveredOption === option.label || option.active ? 1 : 0.7 }"
                    />
                    <!-- <Transition :duration="200"> -->
                    <div
                        class="flex-1 text-sm font-medium leading-5 flex flex-col justify-center overflow-hidden whitespace-nowrap"
                        :class="{ 'max-w-0': !expanded }"
                        v-show="expanded"
                    >
                        {{ option.label }}
                    </div>
                    <!-- </Transition> -->
                </a>
            </template>
        </li>
        <div
            v-if="isSafari"
            class="min-h-[82px]"
        ></div>
    </ul>
</template>

<script lang="ts" setup>
import FileNavIcon from '@/components/Icons/FileNavIcon.vue';
import PayoffVerificationIcon from '@/components/Icons/PayoffVerificationNavIcon.vue';
import SettingsNavIcon from '@/components/Icons/SettingsNavIcon.vue';
import { SideNavSection } from '@/interfaces/navigation/side-nav';
import { debounce } from 'lodash';
import { usePrimeVue } from 'primevue/config';
import { TooltipDirectivePassThroughOptions } from 'primevue/tooltip';
import { defineComponent, ref, computed } from 'vue';
import { useRoute } from 'vue-router';

const PrimeVue = usePrimeVue();
PrimeVue.config.ripple = true;

const icons = {
    FileNavIcon,
    PayoffVerificationIcon,
    SettingsNavIcon,
};

type IconKey = keyof typeof icons;

type MenuOption = {
    url?: string;
    label: string;
    active?: boolean;
    icon?: (typeof icons)[IconKey];
    separator?: boolean;
    sideNavSection?: SideNavSection;
};

defineComponent(icons);
const route = useRoute();

withDefaults(defineProps<{ expanded?: boolean }>(), { expanded: true });
const hoveredOption = ref<string | null>();

const setHoverActive = debounce(function (option: MenuOption, isHovered: boolean) {
    if (!isHovered) {
        hoveredOption.value = null;
        return;
    }
    hoveredOption.value = option.label;
}, 50);

const menuOptions: MenuOption[] = [
    {
        url: '/user/home',
        label: 'Files',
        active: false,
        icon: icons.FileNavIcon,
        sideNavSection: SideNavSection.File,
    },
    {
        url: '/user/payoffs',
        label: 'Payoff Verifications',
        active: false,
        icon: icons.PayoffVerificationIcon,
        sideNavSection: SideNavSection.Payoffs,
    },
    {
        separator: true,
        label: '',
    },
    {
        url: '/user/settings',
        label: 'Settings',
        active: false,
        icon: icons.SettingsNavIcon,
        sideNavSection: SideNavSection.Settings,
    },
];

const menuOptionRouted = menuOptions.map(option => ({
    ...option,
    active: option.sideNavSection === route.meta?.sideNavSection,
}));
const isSafari = computed(() => {
    const userAgent = window.navigator.userAgent;
    return /iP(ad|od|hone)/i.test(userAgent) && /WebKit/i.test(userAgent) && !/(CriOS|FxiOS|OPiOS|mercury)/i.test(userAgent);
});
</script>
