<template>
    <div>
        <div>
            <h1 class="mt-14 mb-1.5">Enter one-time email code</h1>
            <p class="mb-4 text-slate-500 text-sm">
                Email verification is required. Check your <b>email inbox</b> and enter the one-time use code you received.
            </p>
        </div>
        <div>
            <code-input
                type="text"
                @code="captureCode"
                @keyup.enter="validateVerificationCode"
                :error="error"
            ></code-input>
            <div
                v-if="error && !this.verification_code_resent"
                class="text-red-500"
            >
                The code entered does not match the last code sent. Check the number and try again, or click resend code.
            </div>
            <div
                v-if="this.verification_code_resent"
                class="text-red-500"
            >
                Verification Code has been resent.
            </div>
            <a
                href="javascript:void(0)"
                class="text-slate-500 text-sm"
                @click="resendCode"
                >Resend Code</a
            >
        </div>
        <div class="mt-24">
            <button
                type="button"
                class="block w-full text-center bg-brand-primary px-4 py-2 font-medium text-white text-uppercase hover:bg-brand-primary-hover-focus focus:outline-none focus:ring-2 focus:ring-brand-primary-hover-focus focus:ring-offset-2"
                @click="validateVerificationCode"
            >
                Login
            </button>
        </div>
    </div>
</template>

<script>
import CodeInput from './CodeInput.vue';

export default {
    name: 'EmailVerificationComponent',
    emits: ['passcodeValid'],
    props: {},
    data() {
        return {
            verification_code: '',
            verification_code_resent: false,
            error: false,
        };
    },
    methods: {
        validateVerificationCode: function () {
            this.verification_code_resent = false;
            this.$http
                .post('/client/login/validateVerificationCode', {
                    verification_code: this.verification_code,
                })
                .then(response => {
                    if (response.data === 'verification code validated') {
                        this.$emit('verificationCodeValid');
                    }
                })
                .catch(() => {
                    this.error = true;
                });
        },
        captureCode: function (code) {
            this.verification_code = code;
        },
        resendCode: function () {
            this.verification_code_resent = true;
            this.$http.post('/client/login/sendVerificationCode').then(response => {});
        },
    },
    components: {
        'code-input': CodeInput,
    },
};
</script>

<style scoped></style>
