<template>
    <div v-if="shadowAccountId && shadowAccountId !== defaultAccountId">
        <a
            :href="defaultAccountAssignRoute"
            class="bg-pop-400 hover:bg-pop-500 text-white hover:text-white text-center flex items-center align-middle text-sm hover:text-white relative px-2 xl:px-3 py-2 rounded md:shadow-xl"
        >
            <span class="flex-1 font-normal font-inter">
                <span class="xl:text-base lg:text-sm hidden sm:inline-block">
                    <span class="font-inter"> {{ innerWidth < 1200 ? '' : '🥸 You are shadowing ' }} </span>
                    <span class="font-bold font-inter">{{ shadowAccountName }}</span
                    >{{ innerWidth < 1200 ? '' : '.' }}
                </span>
                <span class="text-xs md:text-sm xl:text-base ml-1 underline hover:text-white">{{ 'Stop Shadowing' }}</span>
            </span>
        </a>
    </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from 'vue';
const props = defineProps<{
    shadowAccountId?: number;
    shadowAccountName?: string;
    defaultAccountId: number;
    defaultAccountAssignRoute: string;
}>();
const isSmallScreen = ref<boolean>(false);
const innerWidth = ref<number>(window.innerWidth);
// compute the screen size on mount and on resize
onMounted(() => {
    isSmallScreen.value = window.innerWidth < 1024;
    window.addEventListener('resize', () => {
        isSmallScreen.value = window.innerWidth < 1024;
        innerWidth.value = window.innerWidth;
    });
});
</script>
