<script lang="ts" setup>
import Button from 'primevue/button';
import { useForm } from 'vee-validate';
import { EditWireDocNotesPayload } from './form-payloads';
import { FlatWireDoc } from '@/interfaces/payment-settings/escrow-account';
import { ref } from 'vue';
import dayjs from 'dayjs';
import { EscrowAccountNote } from '@/api/interfaces/escrow-account.api';

const props = defineProps<{ flatWireDoc?: FlatWireDoc; currentNotes: EscrowAccountNote[] }>();
const emits = defineEmits<{
    (event: 'submit', payload: EditWireDocNotesPayload): void;
    (event: 'cancel'): void;
}>();

const { defineField, handleSubmit } = useForm();

const [note] = defineField('note');
const isSubmitAttemptedWithEmptyText = ref(false);

const onSubmit = handleSubmit(values => {
    const hasNoteContent = !!values.note.trim(); // returns true if trimming results in non empty
    isSubmitAttemptedWithEmptyText.value = hasNoteContent;
    if (!hasNoteContent) {
        return;
    }
    emits('submit', { flatWireDoc: props.flatWireDoc, newNote: values.note });
});

const onCancel = () => {
    emits('cancel');
};

const onInput = () => {
    if (isSubmitAttemptedWithEmptyText.value) {
        isSubmitAttemptedWithEmptyText.value = false; // Reset error state when user starts typing
    }
};
</script>

<template>
    <h3 class="text-center font-semibold">Add Note to WireDoc</h3>
    <div class="text-sm font-normal text-gray-400 mb-9 text-center"></div>
    <div class="flex flex-col gap-8 font-medium">
        <div class="flex flex-col gap-1">
            <label
                for="currentNotes"
                class="text-sm font-normal"
                >Current Notes:</label
            >
            <ul
                id="currentNotes"
                class="rounded"
            >
                <li
                    v-for="(note, index) in props.currentNotes"
                    :key="index"
                    class="rounded mb-4 :not(:last-child)"
                >
                    {{ dayjs(note.datetime).format('MM/DD/YYYY hh:mm A') }}: {{ note.note }} - {{ note.user }}
                </li>
            </ul>
        </div>
    </div>
    <br />
    <form @submit="onSubmit">
        <div class="flex flex-col gap-8 font-medium">
            <div class="flex flex-col gap-1">
                <div
                    v-if="isSubmitAttemptedWithEmptyText"
                    class="text-red-500"
                >
                    Cannot submit an empty note.
                </div>
                <label class="text-sm font-normal">New Note:</label>
                <textarea
                    id="note"
                    v-model="note"
                    label="Note"
                    @input="onInput"
                    placeholder="Add a note to this WireDoc"
                    class="rounded"
                ></textarea>
            </div>
            <div class="flex flex-col gap-1">
                <Button
                    label="Add Note"
                    severity="submit"
                    type="submit"
                    :disabled="!note"
                ></Button>
                <Button
                    outlined
                    label="Cancel"
                    type="button"
                    @click="onCancel"
                ></Button>
            </div>
        </div>
    </form>
</template>
