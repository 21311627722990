import LoadingDirective from '@/components/Directives/Loading';
import HttpClient from '@/components/Plugins/HttpClient';
import upload from '@/components/Plugins/Upload';
import { createHead } from '@unhead/vue';
import vClickOutside from 'click-outside-vue3';
import { createPinia, setActivePinia } from 'pinia';
import piniaPluginPersistedState from 'pinia-plugin-persistedstate';
import { createApp } from 'vue/dist/vue.esm-bundler.js';
import * as SpaModule from './vue-app-spa';
// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faArrowDownToLine,
    faArrowRight,
    faArrowUpArrowDown,
    faArrowsRotate,
    faBank,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronUp,
    faCircleDollar,
    faCircleInfo,
    faDollar,
    faEllipsis,
    faFileArrowDown,
    faLock,
    faPlus,
    faPrint,
    faQuestion,
    faSignature,
    faX,
    faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
    faAddressCard,
    faBuildingColumns,
    faFolderArrowDown,
    faLockKeyhole,
    faMemoCircleInfo,
    faMessageDots,
    faPhone,
    faShieldKeyhole,
} from '@fortawesome/pro-regular-svg-icons';

import { faBolt, faBoltAuto, faFilePen, faListTimeline } from '@fortawesome/pro-light-svg-icons';
import LoginFormComponent from './components/auth/LoginFormComponent.vue';
import { buildCompanyPortalRouter } from './spa/routes';

library.add([
    faAddressCard,
    faArrowDownToLine,
    faArrowRight,
    faArrowUpArrowDown,
    faArrowsRotate,
    faBank,
    faBolt,
    faBoltAuto,
    faBuildingColumns,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronUp,
    faCircleDollar,
    faCircleInfo,
    faDollar,
    faEllipsis,
    faFileArrowDown,
    faFilePen,
    faFolderArrowDown,
    faListTimeline,
    faLock,
    faMemoCircleInfo,
    faMessageDots,
    faPhone,
    faPlus,
    faPrint,
    faQuestion,
    faShieldKeyhole,
    faSignature,
    faX,
    faXmark,
    faLockKeyhole,
]);

const modules = {
    LoginFormComponent,
};

const vueExtensions = [vClickOutside, HttpClient, upload, createHead()];

/** Critical: pinia can only be created once to avoid messy multiple instances of the same named stores */
const pinia = createPinia();
pinia.use(piniaPluginPersistedState);
setActivePinia(pinia);

window.initSpaModule = rootElementSelector => {
    SpaModule.mount(rootElementSelector);
};

window.initVueSprinkle = rootElementSelector => {
    const app = createApp();
    SpaModule.decorate(app, true);
    app.use(buildCompanyPortalRouter('/'));

    app.config.globalProperties.$log = console.log;

    for (const extension of vueExtensions) {
        app.use(extension);
    }
    app.mount(rootElementSelector);
    return app;
};

window.initVueComponent = async (elementId, params = {}) => {
    const module = (await import(`./vue-${elementId}.js`)).default;
    const app = createApp(module.component, params.props ?? {});

    app.config.globalProperties.$log = console.log;

    app.component('font-awesome-icon', FontAwesomeIcon);
    for (const extension of vueExtensions) {
        app.use(extension);
    }

    app.use(pinia);
    app.directive('loading', LoadingDirective);
    app.mount(module.element);

    return app;
};

window.loadComponent = componentName => {
    const component = modules[componentName];
    const app = createApp({});
    app.component(component.name.replace(/([a-z0–9])([A-Z])/g, '$1-$2').toLowerCase(), component);
    app.component('font-awesome-icon', FontAwesomeIcon);

    app.config.globalProperties.$log = console.log;

    for (const extension of vueExtensions) {
        app.use(extension);
    }

    app.mount('#app');

    return app;
};
