import { PrimeVuePTOptions } from 'primevue/config';
import { usePassThrough } from 'primevue/passthrough';
import Tailwind from 'primevue/passthrough/tailwind';

import { ButtonDesign } from './button.design';
import { DataTableDesign } from './datatable.design';
import { DropdownDesign } from './dropdown.design';
import { MenuDesign } from './menu.design';
import { MenubarDesign } from './menubar.design';
import { AccordionDesign, AccordionTabDesign } from './accordion.design';
import { TabMenuDesign } from './tab-menu.design';
import { AvatarDesign } from '@/design-system/avatar.design';

const CustomDesign = {
    ...ButtonDesign,
    ...DropdownDesign,
    ...MenuDesign,
    ...MenubarDesign,
    ...DataTableDesign,
    ...AccordionDesign,
    ...AccordionTabDesign,
    ...TabMenuDesign,
    ...AvatarDesign,
} as PrimeVuePTOptions;

export const ClosingLockDesignSystem = usePassThrough(Tailwind, CustomDesign, { mergeProps: false, mergeSections: true });
