<template>
    <div class="col-lg-6 offset-lg-3 md:p-8 md:shadow-xl rounded-md mx-auto mb-5 max-w-[497px]">
        <div class="mb-10">
            <a
                href="/user/mfa/send"
                class="text-sm text-neutral-500 focus:text-primary-600 hover:text-primary-600 btn-link"
                tabindex="1"
                ><font-awesome-icon
                    :icon="['fas', 'angle-left']"
                    class="mr-1"
                />Back</a
            >
        </div>

        <div class="flex mt-5 mb-3">
            <h3 class="text-left font-semibold text-xl font-inter">Multi-factor security</h3>
        </div>
        <p class="text-base mb-10">Please enter the code.</p>

        <div class="flex mb-5">
            <div class="grow">
                <div class="mb-3">
                    <label class="font-inter font-normal text-sm">Authentication Code</label>
                    <code-input
                        @code="captureCode"
                        @keyup.enter="currentUserService.verifyMFACode(props.loginRoute, codeValues)"
                    />
                    <div class="text-center mt-3">
                        <a
                            href=""
                            class="btn-link"
                            @click="
                                e => {
                                    e.preventDefault();
                                    currentUserService.requestMFACode(props.getCodeRoute, 'sms');
                                }
                            "
                            >Resend text</a
                        >
                        |
                        <a
                            href=""
                            class="btn-link"
                            @click="
                                e => {
                                    e.preventDefault();
                                    currentUserService.requestMFACode(props.getCodeRoute, 'call');
                                }
                            "
                            >Call me again</a
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="flex text-center my-10">
            <div class="grow">
                <button
                    class="btn btn-brand btn-circle w-100 text-base"
                    tabindex="4"
                    :disabled="loginButtonDisabled"
                    @click="currentUserService.verifyMFACode(props.loginRoute, codeValues)"
                >
                    Login
                </button>
            </div>
        </div>
        <div class="row">
            <div
                class="col"
                style="text-align: center"
            >
                <p>
                    Need help? Please contact
                    <a
                        :href="'mailto:' + supportEmail"
                        class="btn-link"
                        >{{ supportEmail }}</a
                    >
                </p>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useCurrentUserService } from '@/services/current-user.service';
import codeInput from '@/components/auth/CodeInput.vue';

const props = defineProps<{
    getCodeRoute: string;
    loginRoute: string;
    supportEmail: string;
}>();

const currentUserService = useCurrentUserService();
const loginButtonDisabled = ref(true);
const codeValues = ref('');

function captureCode(code: string) {
    codeValues.value = code;
    if (code.length === 6) {
        loginButtonDisabled.value = false;
    } else {
        loginButtonDisabled.value = true;
    }
}
</script>
