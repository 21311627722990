<template>
    <div class="flex flex-row items-center">
        <h2 class="flex-1">Payment Tiers</h2>
        <div class="flex gap-2">
            <template v-if="tierTableActionSet === 'initializeSetTiers'">
                <Button
                    label="Set Tiers"
                    @click="initializeSetTiers"
                />
            </template>
            <template v-else-if="tierTableActionSet === 'saveInitialTiers'">
                <Button
                    label="Save Changes"
                    @click="() => saveAccountPaymentTiers(true)"
                    :disabled="!saveActionEnabled"
                />
            </template>
            <template v-else-if="tierTableActionSet === 'editExistingTiers'">
                <Button
                    outlined
                    label="Edit Tiers"
                    @click="enableTierEdit"
                />
            </template>
            <template v-else-if="tierTableActionSet === 'saveEditedTiers'">
                <Button
                    outlined
                    label="Cancel"
                    @click="disableTierEdit"
                    v-if="paymentTierGrid.rows.length > 0"
                />
                <Button
                    label="Save Changes"
                    @click="() => saveAccountPaymentTiers(true)"
                    :disabled="!saveActionEnabled"
                />
            </template>
        </div>
    </div>
    <div class="mb-5 mt-5">
        <Message
            severity="error"
            :closable="false"
            v-if="paymentTierGrid.errors.length > 0"
        >
            <template #messageicon><span></span></template>
            <ul>
                <li
                    :key="error.id"
                    v-for="error of paymentTierGrid.errors"
                >
                    {{ error.message }}
                </li>
            </ul>
        </Message>
        <DataTable
            :value="paymentTierGrid.rows"
            :editMode="tierEditState"
            :pt="{
                bodyRow: tierTableActionSet === 'editExistingTiers' ? 'bg-neutral-100' : 'bg-white',
            }"
        >
            <Column
                v-for="col of tierColumns"
                :key="col.field"
                :field="col.field"
                :header="col.header"
                class="w-2/12"
            >
                <template #body="{ data, field }">
                    <template v-if="field !== 'fee_paid_by_account'">
                        <span :class="{ 'text-red-500': erredFields.indexOf(`${data.id}_${field}`) >= 0 }">
                            {{ col.formatter(data[field]) }}
                        </span>
                    </template>
                    <template v-else>
                        {{ data[field] ? 'Yes' : 'No' }}
                    </template>
                    <template v-if="field === 'max_payment'">
                        <template v-if="!tierEditState">
                            <p
                                class="text-xs text-neutral-400 cursor-default"
                                :title="'Last updated on ' + data.updated_at"
                            >
                                {{ col.formatter(data.min_payment) }} - {{ col.formatter(data.max_payment) }}
                            </p>
                        </template>
                    </template>
                </template>
                <template #editor="{ data, field }">
                    <template v-if="field !== 'fee_paid_by_account'">
                        <InputNumber
                            v-model="data[field]"
                            size="small"
                            :pt="{ input: { root: 'pt-0 pl-1 pr-0 pb-0 w-10/12 text-sm' } }"
                            locale="en-US"
                            :minFractionDigits="0"
                            :maxFractionDigits="col.decimalPrecision"
                            @input="
                                event =>
                                    handleNumericCellEditInput(data.id, field as PaymentTierNumericField, event.value as number)
                            "
                        />
                    </template>
                    <template v-else>
                        <select
                            v-model="data[field]"
                            @change="() => handleBooleanCellEditInput(data.id, field, data[field])"
                            class="rounded py-0 px-1 w-20 border bg-neutral-50 text-sm"
                        >
                            <option :value="true">Yes</option>
                            <option :value="false">No</option>
                        </select>
                    </template>
                </template>
            </Column>
            <Column
                field="id"
                header="Actions"
                class="w-1/12"
                :row-editor="false"
            >
                <template #body="{ data }">
                    <Button
                        v-if="
                            tierEditState === 'cell' &&
                            tierTableActionSet !== 'editExistingTiers' &&
                            tierTableActionSet !== 'initializeSetTiers' &&
                            paymentTierGrid.rows.length > 1
                        "
                        link
                        @click="deleteTier((data as PaymentTier).id)"
                        label="Delete"
                        :pt="{ root: 'p-0', label: 'text-red-600 text-sm font-thin' }"
                    />
                </template>
            </Column>
        </DataTable>
        <div class="flex flex-row">
            <p
                class="mt-3 font-medium flex-1"
                v-if="tierTableActionSet === 'editExistingTiers'"
            >
                Maximum payment limit per file is {{ formatMoney(maxPaymentTier) }}
            </p>
            <div
                v-if="tierTableActionSet !== 'editExistingTiers' && tierTableActionSet !== 'initializeSetTiers'"
                class="flex-1 flex justify-end"
            >
                <Button
                    link
                    @click="addNewTier"
                    >Add Tier +</Button
                >
            </div>
        </div>
        <SavePendingChangesModal
            :is-open="confirmSavePendingModalOpen"
            :has-dirty-state="paymentTierGrid.isDirty"
            @save-account-payment-tiers="() => saveAccountPaymentTiers(false)"
            @confirm-cancel-tier-edit="confirmCancelTierEdit"
        />
    </div>
</template>

<script lang="ts" setup>
import { usePaymentTierService } from '@/services/price-tier.service';
import { formatMoney } from '@/shared/utils/format-number';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputNumber from 'primevue/inputnumber';
import { computed, onMounted, ref } from 'vue';

import { CompanyAccount } from '@/api/interfaces/company-account.api';
import { PaymentTier } from '@/api/interfaces/payment-tier.api';

import { cloneDeep } from 'lodash';
import Message from 'primevue/message';
import SavePendingChangesModal from './modals/SavePendingChanges.modal.vue';

const { companyAccount } = defineProps<{ companyAccount: CompanyAccount }>();

const confirmSavePendingModalOpen = ref(false);

type PaymentTierGridEditError = { field: string; id: PaymentTier['id']; message: string };
const paymentTierGrid = ref<{ rows: PaymentTier[]; isDirty: boolean; errors: PaymentTierGridEditError[] }>({
    rows: [],
    isDirty: false,
    errors: [],
});
const erredFields = computed(() => Array.from(new Set(paymentTierGrid.value.errors.map(error => `${error.id}_${error.field}`))));
const saveActionEnabled = computed(() => paymentTierGrid.value?.isDirty && paymentTierGrid.value.rows?.length >= 1);

const paymentTierService = usePaymentTierService();
const maxPaymentTier = computed(() => {
    const sorted = Array.from(paymentTierService.paymentTiersList).sort((a, b) => (a?.max_payment > b?.max_payment ? -1 : 1));
    return sorted[0]?.max_payment ?? 0;
});

/** Represents the 4 control modes for the tier table: uninitialized, initialized not saved, existing, editing existing */
type TierTableActionSets = 'initializeSetTiers' | 'saveInitialTiers' | 'editExistingTiers' | 'saveEditedTiers';
const tierTableActionSet = ref<TierTableActionSets>('editExistingTiers');
const tierEditState = ref<'cell' | undefined>();

function setEditState(canEdit: boolean) {
    if (canEdit) {
        return (tierEditState.value = 'cell');
    }
    tierEditState.value = undefined;
}

function enableTierEdit() {
    tierTableActionSet.value = 'saveEditedTiers';
    setEditState(true);
}

function disableTierEdit() {
    confirmCancelTierEdit();
}

function validatePaymentTierGrid() {
    const errors: PaymentTierGridEditError[] = [];

    const { rows } = paymentTierGrid.value;

    // check if all rows have a max limit
    const maxLimitMinErrorEntries = rows.filter(row => row.max_payment < 0.01).map(row => row.id);
    const errorsMaxLimit = maxLimitMinErrorEntries.map(
        id =>
            ({
                id,
                field: 'max_payment',
                message: 'The Maximum Limit can not be $0',
            }) as PaymentTierGridEditError,
    );
    errors.push(...errorsMaxLimit);

    // check if there are duplicate max limit values
    const mappedValues = new Map<PaymentTier['max_payment'], number>(); // count instances of each max value
    const duplicatedMaxErrorEntries: PaymentTier[] = [];
    for (const row of rows) {
        const { max_payment } = row;
        mappedValues.set(max_payment, (mappedValues.get(max_payment) ?? 0) + 1);
        if ((mappedValues.get(max_payment) ?? 0) > 1) {
            duplicatedMaxErrorEntries.push(row);
        }
    }
    const errorsDuplicatedMaxLimit =
        duplicatedMaxErrorEntries.map(
            row =>
                ({
                    id: row.id,
                    field: 'max_payment',
                    message: 'The Maximum Limit can not be the same as an existing tier',
                }) as PaymentTierGridEditError,
        ) ?? [];
    errors.push(...errorsDuplicatedMaxLimit);

    const erredFields = (paymentTierGrid.value.errors = errors);
    return { errors, erredFields };
}

async function confirmCancelTierEdit() {
    tierTableActionSet.value = 'editExistingTiers';
    confirmSavePendingModalOpen.value = false;
    setEditState(false);
    resetPaymentGrid();
}

async function loadAccountPaymentTiers(accountId: number) {
    if (accountId) {
        await paymentTierService.loadPaymentTiers(accountId);
        resetPaymentGrid();
    }
}

function resetPaymentGrid() {
    const sortedTiers = cloneDeep(paymentTierService.paymentTiersList || []).sort((a, b) =>
        a?.max_payment > b?.max_payment ? 1 : -1,
    );
    paymentTierGrid.value = { rows: sortedTiers, isDirty: false, errors: [] };
    tierTableActionSet.value = paymentTierGrid.value.rows.length > 0 ? 'editExistingTiers' : 'initializeSetTiers';
    setEditState(false);
}

function initializeSetTiers() {
    const defaultTierEntries: PaymentTier[] = [];
    defaultTierEntries.push({
        id: `${Math.random()}`,
        created_at: new Date(),
        updated_at: new Date(),
        max_payment: 10000,
        min_payment: 0,
        base_fee: 25,
        variable_percentage_fee: 0,
        variable_flat_fee: 0,
        hold_time: 0,
        fee_paid_by_account: false,
    });
    defaultTierEntries.push({
        id: `${Math.random()}`,
        created_at: new Date(),
        updated_at: new Date(),
        max_payment: 100000,
        min_payment: 10000.01,
        base_fee: 25,
        variable_percentage_fee: 0.199,
        variable_flat_fee: 0,
        hold_time: 0,
        fee_paid_by_account: false,
    });
    resetPaymentGrid();
    paymentTierGrid.value.rows = defaultTierEntries;
    tierTableActionSet.value = 'saveInitialTiers';
    setEditState(true);
}

async function saveAccountPaymentTiers(checkDirty = true) {
    if (checkDirty && paymentTierGrid.value.isDirty) {
        confirmSavePendingModalOpen.value = true;
    } else {
        confirmSavePendingModalOpen.value = false;
        const validation = validatePaymentTierGrid();
        if (validation.errors.length > 0) {
            return;
        }
        if (companyAccount) {
            try {
                await paymentTierService.savePaymentTiers(companyAccount?.id, paymentTierGrid.value.rows || []);
                await paymentTierService.loadPaymentTiers(companyAccount.id);
                resetPaymentGrid();
            } catch (error) {
                console.error('Error saving tiers:' + error);
            }
        }
    }
}

function addNewTier() {
    paymentTierGrid.value.isDirty = true;
    paymentTierGrid.value.rows.push({
        base_fee: 0,
        created_at: new Date(),
        fee_paid_by_account: false,
        hold_time: 0,
        id: `${Math.random()}`,
        max_payment: 0,
        min_payment: 0,
        updated_at: new Date(),
        variable_percentage_fee: 0,
        variable_flat_fee: 0,
    });
}

function findRowById(rowId: PaymentTier['id']) {
    const itemIndex = paymentTierGrid.value.rows.findIndex(row => row.id === rowId);
    const item = paymentTierGrid.value.rows[itemIndex];
    return { itemIndex, item, found: !!item };
}

function deleteTier(deletedTierId: string) {
    paymentTierGrid.value.isDirty = true;
    paymentTierGrid.value.rows = paymentTierGrid.value.rows.filter(row => row.id !== deletedTierId);
}

type PaymentTierNumericField = { [K in keyof PaymentTier]: PaymentTier[K] extends number ? K : never }[keyof PaymentTier];
function handleNumericCellEditInput(rowId: PaymentTier['id'], field: PaymentTierNumericField, inputValue: number) {
    const newValue = inputValue || 0;

    const changedItem = findRowById(rowId);
    if (changedItem?.item && changedItem.item[field] !== newValue) {
        changedItem.item[field] = newValue || 0;
        changeRowDirtyValue(changedItem.item);
    }
}

type PaymentTierBooleanField = { [K in keyof PaymentTier]: PaymentTier[K] extends boolean ? K : never }[keyof PaymentTier];

function handleBooleanCellEditInput(rowId: PaymentTier['id'], field: PaymentTierBooleanField, inputValue: boolean) {
    const newValue = inputValue || false;

    const changedItem = findRowById(rowId);
    if (changedItem?.item && changedItem.item[field] !== newValue) {
        changedItem.item[field] = newValue || false;
        changeRowDirtyValue(changedItem.item);
    }
}

function changeRowDirtyValue(newRowValue: PaymentTier) {
    if (!newRowValue?.id) {
        return;
    }
    paymentTierGrid.value.isDirty = true;
    const rowById = findRowById(newRowValue.id);
    paymentTierGrid.value.rows[rowById.itemIndex] = { ...newRowValue };
}

type PaymentTierColConfig = {
    field: string;
    header: string;
    decimalPrecision?: number;
    formatter: (val: unknown) => unknown;
};
const tierColumns = computed<PaymentTierColConfig[]>(
    () =>
        [
            { field: 'max_payment', header: 'Max Limit', formatter: n => formatMoney((n as number) || 0), decimalPrecision: 2 },
            { field: 'base_fee', header: 'Base Fee', formatter: n => formatMoney((n as number) || 0), decimalPrecision: 2 },
            {
                field: 'variable_percentage_fee',
                header: 'Variable Percentage Fee',
                formatter: n => `${n.toFixed(3) || 0} %`,
                decimalPrecision: 3,
            },
            {
                field: 'variable_flat_fee',
                header: 'Variable Flat Fee',
                formatter: n => formatMoney((n as number) || 0),
                decimalPrecision: 2,
            },
            { field: 'hold_time', header: 'Hold Time Hours', formatter: n => `${n || 0} h`, decimalPrecision: 0 },
            {
                field: 'fee_paid_by_account',
                header: 'Fee Paid by ' + companyAccount?.name,
                formatter: n => `${n}`,
                decimalPrecision: 0,
            },
        ] as const,
);

onMounted(async () => {
    loadAccountPaymentTiers(companyAccount.id);
});
</script>
