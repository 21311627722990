import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useCompanyNavigationStore = defineStore(
    'company',
    () => {
        const showSideNav = ref(false);
        const showVendorIntegrationTab = ref(false);
        const setShowSideNav = (value: boolean) => {
            showSideNav.value = value;
        };
        const setShowVendorIntegrationTab = (value: boolean) => {
            showVendorIntegrationTab.value = value;
        };

        return {
            showSideNav,
            showVendorIntegrationTab,
            setShowSideNav,
            setShowVendorIntegrationTab,
        };
    },
    {
        persist: {
            storage: sessionStorage,
            debug: true,
        },
    },
);
