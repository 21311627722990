<template>
    <div
        class="h-auto transition-all ease-in-out duration-200 flex flex-col py-2 border-r border-neutral-200"
        :class="{ 'w-16': !expanded, 'w-64': expanded }"
    >
        <div class="flex justify-end mb-2">
            <div
                class="bg-neutral-100 hover:bg-neutral-200 cursor-pointer expansion-control-shadow w-5 py-1 items-center text-center rounded-l-[4px] border-red-500 -border"
                @click="toggleSideNav"
            >
                <template v-if="expanded"><ChevLeft with-shadow /></template>
                <template v-else><ChevRight with-shadow /></template>
            </div>
        </div>

        <slot
            name="content"
            :is-panel-expanded="expanded"
        ></slot>
    </div>
</template>

<script setup lang="ts">
import ChevLeft from '@/components/Icons/ChevLeft.vue';
import ChevRight from '@/components/Icons/ChevRight.vue';
import hotkeys from 'hotkeys-js';
import { storeToRefs } from 'pinia';
import { useCompanyNavigationStore } from '@/stores/company/company-navigation.store';
import { onMounted, onUnmounted } from 'vue';

const companyNavigationStore = useCompanyNavigationStore();
const { showSideNav } = storeToRefs(companyNavigationStore);
// declare slots expanded and collapsed
defineSlots<{
    content: (props: { isPanelExpanded: boolean }) => void;
}>();

function toggleSideNav() {
    expanded.value = !expanded.value;
    companyNavigationStore.setShowSideNav(!expanded.value);
}

const expanded = defineModel<boolean>('expanded', { default: true });
onMounted(() => {
    hotkeys('ctrl+.,cmd+.', () => {
        toggleSideNav();
    });

    if (showSideNav) {
        expanded.value = showSideNav.value;
    }
});
onUnmounted(() => {
    hotkeys.unbind('ctrl+.,cmd+.');
});
</script>

<style>
.expansion-control-shadow {
    box-shadow:
        0px 1px 2px -1px rgba(16, 24, 40, 0.1),
        0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}
</style>
