<script lang="ts" setup>
import Button from 'primevue/button';

import AddressAutocompleteSearch from '@/components/Components/Inputs/AddressAutocompleteSearch.vue';
import { ParsedGoogleMapsPlace } from '@/shared/utils/google-place';
import * as EmailValidator from 'email-validator';
import InputMask from 'primevue/inputmask';
import InputText from 'primevue/inputtext';
import { useField, useForm } from 'vee-validate';
import { computed, onMounted } from 'vue';

import { FlatWireDoc } from '@/interfaces/payment-settings/escrow-account';
import { useEscrowAccountService } from '@/services/escrow-account.service';
import { EditBranchInfoPayload } from './form-payloads';

const props = defineProps<{ flatWireDoc: FlatWireDoc }>();
const escrowAccountService = useEscrowAccountService();
const initialAddress = computed(() => {
    const { address_line_1, city, state, zip } = props.flatWireDoc.address;
    return [address_line_1, city, state, zip].filter(i => !!i).join(', ');
});

const emits = defineEmits<{
    (event: 'submit', payload: EditBranchInfoPayload): void;
    (event: 'cancel'): void;
}>();

const { handleSubmit, validate, submitCount } = useForm();

const { value: email, errorMessage: emailErrorMessage } = useField<string>('contactEmail', value => {
    return submitCount.value > 0 ? EmailValidator.validate(value) || 'E-mail is invalid' : true;
});
const { value: phone, errorMessage: phoneErrorMessage } = useField<string>('phone', value => {
    if (submitCount.value > 0) {
        return value ? true : 'Please add a phone number';
    }
    return true;
});

type FormSelectedAddress = Pick<ParsedGoogleMapsPlace, 'addressLineOne' | 'city' | 'stateShort' | 'zipCode'>;

const { value: selectedAddress, errorMessage: addressErrorMessage } = useField<FormSelectedAddress>('selectedAddress', value => {
    if (submitCount.value === 0) {
        return true;
    }
    const errorMessage = 'Address is not valid. Please select a valid street level address including the number';
    if (!value) {
        return errorMessage;
    }
    const valid = escrowAccountService.isAddressComplete({
        address_line_1: value?.addressLineOne,
        address_line_2: '',
        city: value?.city,
        state: value?.stateShort,
        zip: value?.zipCode,
    });
    return valid || errorMessage;
});

const onSubmit = handleSubmit(async values => {
    await validate();
    if (selectedAddress.value) {
        const { city, zipCode, addressLineOne, stateShort } = selectedAddress.value;
        const payload: EditBranchInfoPayload = {
            flatWireDoc: props.flatWireDoc,
            values: {
                addressCity: city,
                addressLineOne: addressLineOne,
                addressLineTwo: '',
                addressState: stateShort,
                addressZip: zipCode,
                email: values.contactEmail,
                phone: values.phone.replace(/[^\d]/gi, ''),
            },
        };
        emits('submit', payload);
    }
});

const onCancel = () => {
    emits('cancel');
};

function handleAddressSelected(address: ParsedGoogleMapsPlace) {
    selectedAddress.value = address;
}
onMounted(() => {
    const { address, contact } = props.flatWireDoc;
    email.value = contact?.contact_email || '';
    phone.value = contact?.phone || '';
    selectedAddress.value = {
        addressLineOne: address?.address_line_1,
        city: address.city,
        stateShort: address.state,
        zipCode: address.zip,
    };
});
</script>

<template>
    <form @submit="onSubmit">
        <h3 class="text-center font-semibold mb-9">Enter Branch Information</h3>
        <div class="flex flex-col gap-8 font-medium">
            <div class="flex flex-col gap-1">
                <label
                    for="email"
                    class="text-sm font-normal"
                    >Contact E-mail:</label
                >
                <InputText
                    v-model="email"
                    type="email"
                    placeholder=""
                    slot-char="Contact E-mail"
                />
                <small
                    class="text-red-500 text-xs"
                    v-show="emailErrorMessage"
                    >{{ emailErrorMessage }}</small
                >
            </div>
            <div class="flex flex-col gap-1">
                <label
                    for="phone"
                    class="text-sm font-normal"
                    >Phone Number:</label
                >
                <InputMask
                    v-model="phone"
                    mask="(999) 999-9999"
                    placeholder="Phone Number"
                />
                <small
                    class="text-red-500 text-xs"
                    v-show="phoneErrorMessage"
                    >{{ phoneErrorMessage }}</small
                >
            </div>
            <div class="flex flex-col gap-1">
                <label
                    for="address"
                    class="text-sm font-normal"
                    >Address:</label
                >
                <!-- <InputText v-model="address" /> -->
                <AddressAutocompleteSearch
                    :initial-value="initialAddress"
                    @location-selected="handleAddressSelected"
                ></AddressAutocompleteSearch>
                <small
                    class="text-red-500 text-xs"
                    v-show="addressErrorMessage"
                    >{{ addressErrorMessage }}</small
                >
            </div>

            <div class="flex flex-col gap-1">
                <Button
                    label="Save Branch Information"
                    type="submit"
                ></Button>
                <Button
                    outlined
                    label="Cancel"
                    type="button"
                    @click="onCancel"
                ></Button>
            </div>
        </div>
    </form>
</template>
