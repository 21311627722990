<script setup lang="ts">
import { parseGoogleMapsPlace, type ParsedGoogleMapsPlace } from '@/shared/utils/google-place';
import { useHead } from '@unhead/vue';
import { debounce, reject } from 'lodash';

import AutoComplete from 'primevue/autocomplete';
import { computed, onBeforeMount, ref } from 'vue';
const googleAPiKey = import.meta.env.VITE_GOOGLE_API_KEY;
useHead({
    script: [
        {
            src: `https://maps.googleapis.com/maps/api/js?key=${googleAPiKey}&libraries=places&callback=console.log`,
            async: true,
        },
    ],
});

const props = defineProps<{ initialValue?: string }>();
const emits = defineEmits<{
    (event: 'location-selected', payload: ParsedGoogleMapsPlace): void;
}>();

const dummyMap = ref<HTMLDivElement>();
const searchTerm = ref<string>();
type Suggestion = {
    label: string;
    searchResult: google.maps.places.QueryAutocompletePrediction;
};
const suggestions = ref<Suggestion[]>([]);
const selectedLocation = ref<ParsedGoogleMapsPlace>();

const autoCompleteService = computed(() => {
    return new window.google.maps.places.AutocompleteService();
});

const placesService = computed(() => {
    const map = new window.google.maps.Map(dummyMap.value as HTMLDivElement);
    return new window.google.maps.places.PlacesService(map);
});

async function getPlaceDetails(placeId: string): Promise<ParsedGoogleMapsPlace> {
    return new Promise(resolve => {
        placesService.value.getDetails({ placeId }, result => {
            if (result) {
                resolve(parseGoogleMapsPlace(result));
            }
            reject('could not fetch details');
        });
    });
}

const handleSearchTermChange = debounce(() => {
    if (searchTerm.value) {
        autoCompleteService.value?.getQueryPredictions({ input: searchTerm.value }, async (results, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                suggestions.value =
                    results?.map(
                        result =>
                            ({
                                searchResult: result,
                                label: result.description,
                            }) as Suggestion,
                    ) ?? [];
            }
        });
    }
}, 500);

async function handleItemSelected(selectedSuggestion: Suggestion) {
    if (selectedSuggestion?.searchResult?.place_id) {
        const details = await getPlaceDetails(selectedSuggestion?.searchResult?.place_id || '');
        selectedLocation.value = details;
        emits('location-selected', details);
    }
}

onBeforeMount(() => {
    searchTerm.value = props.initialValue;
});
</script>

<template>
    <AutoComplete
        @input="handleSearchTermChange"
        v-model="searchTerm"
        :suggestions="suggestions"
        option-label="label"
        @item-select="item => handleItemSelected(item.value)"
        class="w-full"
        :pt="{ input: 'w-full', loadingIcon: 'hidden', item: 'py-1' }"
    />
    <div
        ref="dummyMap"
        style="display: none"
    ></div>
</template>
