import { AvatarProps, AvatarPassThroughMethodOptions } from 'primevue/avatar';
import { PrimeVuePTOptions } from 'primevue/config';

export const AvatarDesign = {
    avatar: {
        root: {
            class: 'bg-neutral-800 text-white flex items-center justify-center rounded-full text-base h-8 w-8 -ml-4 border-2 border-white dark:border-gray-900',
        },
    },
    image: ({ props }: { props: AvatarProps }) => ({
        class: [
            'h-full w-full',
            {
                'rounded-lg': props.shape == 'square',
                'rounded-full': props.shape == 'circle',
            },
        ],
    }),
} as Pick<PrimeVuePTOptions, 'avatar'>;
