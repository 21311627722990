import { EscrowAccountBranch, EscrowAccountTitleCompany, EscrowAccountWireDoc } from '@/api/interfaces/escrow-account.api';

export enum EscrowAccountWireStatus {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
    PENDING = 'PENDING',
    LOCKED = 'LOCKED',
    UNKNOWN = 'UNKNOWN',
}
export const EscrowAccountStatusDisplayLabel = {
    [EscrowAccountWireStatus.ENABLED]: 'Enabled',
    [EscrowAccountWireStatus.DISABLED]: 'Disabled',
    [EscrowAccountWireStatus.PENDING]: 'Pending',
    [EscrowAccountWireStatus.LOCKED]: 'Locked',
    [EscrowAccountWireStatus.UNKNOWN]: 'Unknown', // fallback for edge cases
} as Readonly<Record<EscrowAccountWireStatus, string>>;

/** The following structures are extensions of the EscrowAccount types
 * returned by the api, but with the status resolved as a field at the
 * wire doc level
 */
export type EscrowAccountTitleCompanyExtended = Omit<EscrowAccountTitleCompany, 'branches'> & {
    branches: EscrowAccountBranchExtended[];
};
export type EscrowAccountBranchExtended = Omit<EscrowAccountBranch, 'wire_docs'> & {
    wire_docs: EscrowAccountWireDocExtended[];
};
export type EscrowAccountWireDocExtended = Omit<EscrowAccountWireDoc, 'payment_start_date'> & {
    status: EscrowAccountWireStatus;
    payment_start_date: Date | null;
};

/** A wire doc with corresponding branch, contact and address information */
export type FlatWireDoc = EscrowAccountWireDocExtended & {
    branchName: EscrowAccountBranchExtended['name'];
    branchId: EscrowAccountBranchExtended['id'];
    contact: EscrowAccountBranchExtended['contact'];
    address: EscrowAccountBranchExtended['address'];
};
