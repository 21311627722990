<template>
    <TabNavContainer title="Files">
        <template #navigation>
            <TabMenu
                :model="items"
                :activeIndex="activeIndex"
            >
            </TabMenu>
        </template>
    </TabNavContainer>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import TabMenu from 'primevue/tabmenu';
import TabNavContainer from './TabNavContainer.vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const props = defineProps<{
    hasPostCloseAccess: string;
}>();

const items = ref([
    { label: 'Pre-Close', url: '/user/home' },
    { label: 'Post-Close', url: '/user/postclose' },
]);

const activeIndex = ref(items.value.findIndex(item => item.url === route.path));

// shoe/hide post-close tab based on flag sent from the BE
if (props.hasPostCloseAccess !== '1') {
    items.value = items.value.filter(item => item.label !== 'Post-Close');
}
</script>
