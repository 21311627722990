import { PrimeVuePTOptions } from 'primevue/config';

export const AccordionDesign = {} as Pick<PrimeVuePTOptions, 'accordion'>;

export const AccordionTabDesign = {
    accordiontab: {
        headerAction: { class: 'bg-white flex flex-row-reverse' },
        headerTitle: { class: 'flex-1' },
    },
} as Pick<PrimeVuePTOptions, 'accordiontab'>;
