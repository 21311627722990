export class RequestUnknownError extends Error {
    private metadata: object;

    constructor(message: string, metadata: object) {
        super(message);
        this.metadata = metadata;
    }

    toString() {
        return `${this.message}, ${this.metadata ? JSON.stringify(this.metadata) : ''}`;
    }
}
