<script setup lang="ts">
import ClHeader from '@/components/Navigation/ClHeader.vue';
import EmployeeNavigation from '@/components/Navigation/top-nav/EmployeeNavigation.vue';
import ShadowWarning from '@/components/Navigation/top-nav/ShadowWarning.vue';
import { useCurrentUserService } from '@/services/current-user.service';
import { onMounted, ref } from 'vue';
import DefaultLayout from './../layouts/DefaultLayout.vue';

const isShadowing = ref(false);
const currentUserService = useCurrentUserService();
const assignRoute = ref('');
onMounted(() => {
    currentUserService.loadGlobalUserOrLogout();
    isShadowing.value = checkIfIsShadowing();
    assignRoute.value = `${window.location.origin}/accounts/assignCurrentUser?account_id=${currentUserService.currentUserMeta.supportAccountId}`;
});

function checkIfIsShadowing() {
    const { supportAccountId, userAccountId } = currentUserService.currentUserMeta;
    return !!supportAccountId && !!userAccountId && supportAccountId !== userAccountId;
}
</script>

<template>
    <DefaultLayout>
        <template #header>
            <ClHeader portal="employee">
                <template v-slot:navigation> <EmployeeNavigation /> </template>
                <template #warning>
                    <shadow-warning
                        v-if="isShadowing"
                        :shadow-account-id="currentUserService.currentUserMeta.userAccountId"
                        :default-account-assign-route="assignRoute"
                    />
                </template>
            </ClHeader>
        </template>
        <template #body>
            <router-view />
        </template>
    </DefaultLayout>
</template>

<style scoped>
* {
    font-family: Inter;
    line-height: 1.5;
    font-weight: 400;

    color-scheme: light;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
}
</style>
