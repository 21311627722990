<template>
    <TabNavContainer title="Settings">
        <template #navigation>
            <TabMenu
                :model="items"
                :activeIndex="activeIndex"
            >
            </TabMenu>
        </template>
    </TabNavContainer>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import TabMenu from 'primevue/tabmenu';
import TabNavContainer from './TabNavContainer.vue';
import { useCompanyNavigationStore } from '@/stores/company/company-navigation.store';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';

const companyNavigationStore = useCompanyNavigationStore();
const route = useRoute();
const { showVendorIntegrationTab } = storeToRefs(companyNavigationStore);

const props = defineProps<{
    userRole: string;
}>();

const items = ref([
    { label: 'Account', url: '/user/settings#account', command: () => settingsHideShow('#account') },
    { label: 'Branch', url: '/user/settings#branch', command: () => settingsHideShow('#branch') },
    { label: 'Users', url: '/user/manage' },
    { label: '|', separator: true, disabled: true },
    { label: 'Wire Instructions', url: '/user/settings#wire', command: () => settingsHideShow('#wire') },
    { label: 'Identity Verification', url: '/user/settings#account-idv', command: () => settingsHideShow('#account-idv') },
    { label: '|', separator: true, disabled: true },
    { label: 'Multi-Factor Authentication', url: '/user/settings#mfa', command: () => settingsHideShow('#mfa') },
    { label: 'Single Sign-On', url: '/user/settings#sso', command: () => settingsHideShow('#sso') },
    { label: 'Vendor Integration', url: '/user/settings#vendor', command: () => settingsHideShow('#vendor') },
]);

// Used for tab item active state styling
const activeIndex = ref(route.hash ? items.value.findIndex(item => item.url === route.fullPath) : activeIndexNoHash());
// Used for showing and hiding settings sections without page reload
function settingsHideShow(id: string) {
    document.querySelectorAll('.settings-tabnav-section').forEach(el => {
        el.classList.add('hidden');
    });
    document.querySelector(id)?.classList.remove('hidden');
}

// Used for setting the active tab item index for irregular routes on the settings page
function activeIndexNoHash() {
    if (route.path === '/user/manage') {
        // User tab
        return 2;
    } else {
        // Account tab
        return 0;
    }
}

onMounted(() => {
    // Browser back/forward button event listener
    window.addEventListener('hashchange', event => {
        // set active menu item based on hash
        activeIndex.value = route.hash ? items.value.findIndex(item => item.url === route.fullPath) : 0;
        // show/hide settings sections based on hash
        route.hash ? settingsHideShow(route.hash) : settingsHideShow('#account');
    });

    // show/hide users tab based on user privileges
    if (props.userRole === 'User') {
        items.value = items.value.filter(item => item.label !== 'Users');
    }
    const pathsToCheck = ['user/settings'];

    const pathIncludesPathToCheck = pathsToCheck.some(pathToCheck => route.path.includes(pathToCheck));
    // remove "Vendor Integration" from menu if no vendor integrations are available for the user
    if (pathIncludesPathToCheck && document.querySelector('#vendor')) {
        companyNavigationStore.setShowVendorIntegrationTab(!!document.querySelector('#vendor'));
    } else if (pathIncludesPathToCheck && !document.querySelector('#vendor')) {
        companyNavigationStore.setShowVendorIntegrationTab(false);
    }

    // used to show/hide vendor integration tab on /user/manage page
    if (!showVendorIntegrationTab.value) {
        items.value = items.value.filter(item => item.label !== 'Vendor Integration');
    }
});
</script>
