<template>
    <div class="mt-5 mb-3">
        <a
            href="#"
            onclick="history.back()"
            class="text-slate-500 no-underline"
        >
            <font-awesome-icon
                icon="fa-solid fa-chevron-left"
                size="sm"
            />
            Back To Account
        </a>
    </div>
    <PageSection class="py-5">
        <h1>Payment Settings</h1>
    </PageSection>
    <PageSection>
        <PaymentTierEditor
            :company-account="companyAccount"
            v-if="companyAccount"
        ></PaymentTierEditor>
    </PageSection>
    <PageSection class="flex flex-row items-center">
        <h2 class="flex-1">Escrow Accounts</h2>
        <Button
            outlined
            @click="toggleAccountOptions"
            :disabled="escrowAccountService.selectedWireDocs.length === 0"
        >
            <template #default>
                <span class="text-base font-medium">Actions</span>
                <font-awesome-icon
                    icon="fa-chevron-down"
                    class="border-w ml-1"
                ></font-awesome-icon>
            </template>
        </Button>
        <Menu
            ref="menu"
            :model="escrowAccountOptions"
            :popup="true"
        >
            <template #item="{ item, props }">
                <a
                    class="flex"
                    v-bind="props.action"
                >
                    <span :class="item.icon" />
                    <span :class="{ 'text-red-600': item.danger }">{{ item.label }}</span>
                </a>
            </template>
        </Menu>
    </PageSection>
    <PageSection>
        <Message
            severity="warn"
            :pt="{ icon: 'hidden' }"
            v-if="escrowAccountService.bulkStatusChangeErrors.length > 0"
        >
            <div
                class="ml-2"
                v-for="(error, index) of escrowAccountService.bulkStatusChangeErrors"
                :key="index"
                v-html="error"
            ></div>
        </Message>
    </PageSection>
    <PageSection
        :key="escrowAccount.id"
        v-for="escrowAccount in escrowAccountService.escrowAccounts"
    >
        <Accordion
            :active-index="0"
            :pt="{ accordiontab: { content: 'bg-white' } }"
            v-if="companyAccount?.id"
        >
            <AccordionTab :header="escrowAccount.name">
                <EscrowAccountTable
                    :escrow-account-branches="escrowAccount.branches"
                    :account-id="companyAccount?.id"
                ></EscrowAccountTable>
            </AccordionTab>
        </Accordion>
    </PageSection>
    <Dialog
        v-model:visible="escrowAccountService.bulkConfirmationPending.value"
        @hide="() => escrowAccountService.cancelBulkStatusChange()"
        @cancel="() => escrowAccountService.cancelBulkStatusChange()"
        header=""
        modal
        :style="{ width: '500px', 'max-width': '672px' }"
        dismissableMask
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
        :draggable="false"
    >
        <FormEditStartDate
            @submit="handleConfirmStartDate"
            v-if="escrowAccountService.intendedBulkTargetStatus.value === EscrowAccountWireStatus.ENABLED"
            :suggested-start-date="escrowAccountService.suggestedStartDate"
            @cancel="() => escrowAccountService.cancelBulkStatusChange()"
        />
        <FormDisablePayment
            v-if="escrowAccountService.intendedBulkTargetStatus.value === EscrowAccountWireStatus.DISABLED"
            @submit="handleBulkDisable"
            @cancel="() => escrowAccountService.cancelBulkStatusChange()"
        />
    </Dialog>
</template>

<script lang="ts" setup>
import PageSection from '@/components/PageSection.vue';
import { useCompanyAccountService } from '@/services/account.service';
import { useEscrowAccountService } from '@/services/escrow-account.service';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import { onMounted, ref } from 'vue';

import { CompanyAccount } from '@/api/interfaces/company-account.api';
import { useRoute } from 'vue-router';
import EscrowAccountTable from './EscrowAccountTable.vue';

import { EscrowAccountWireStatus } from '@/interfaces/payment-settings/escrow-account';
import Dialog from 'primevue/dialog';
import Message from 'primevue/message';
import PaymentTierEditor from './PaymentTierEditor.vue';
import FormDisablePayment from './forms/FormDisablePayment.vue';
import FormEditStartDate from './forms/FormEditStartDate.vue';
import { EditStartDateSubmitPayload } from './forms/form-payloads';
import { DisablePaymentSubmitPayload } from './forms/form-payloads';

const menu = ref();

const currentRoute = useRoute();

const companyAccount = ref<CompanyAccount>();

const escrowAccountService = useEscrowAccountService();
const accountService = useCompanyAccountService();

/** Escrow account lists  */
const toggleAccountOptions = (event: MouseEvent) => {
    menu.value.toggle(event);
};

const escrowAccountOptions = ref([
    {
        items: [
            {
                label: 'Enable Payments',
                command: () => {
                    if (companyAccount.value?.id) {
                        escrowAccountService.initiateBulkStatusChange(companyAccount.value?.id, EscrowAccountWireStatus.ENABLED);
                    }
                },
            },
            {
                label: 'Disable Payments',
                danger: true,
                command: () => {
                    if (companyAccount.value?.id) {
                        escrowAccountService.initiateBulkStatusChange(companyAccount.value?.id, EscrowAccountWireStatus.DISABLED);
                    }
                },
            },
        ],
    },
]);

function handleConfirmStartDate(startDateSubmit: EditStartDateSubmitPayload) {
    escrowAccountService.setChosenStartDate(startDateSubmit.values.startDate);
}

function handleBulkDisable(noteSubmit: DisablePaymentSubmitPayload) {
    if (companyAccount.value?.id) {
        escrowAccountService.bulkDisablePayment(companyAccount.value?.id, noteSubmit.note);
    }
}

onMounted(async () => {
    const { accountId } = currentRoute.params;
    if (accountId) {
        const accountIdNumeric = Number(accountId);
        companyAccount.value = (await accountService.getAccountById(accountIdNumeric)) ?? undefined;
        // loadAccountPaymentTiers(accountIdNumeric);
        escrowAccountService.loadAccountEscrowAccounts(accountIdNumeric);
    }
});
</script>
