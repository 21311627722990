import MultiFactorAuthenticationSend from '@/company-portal/pages/MultiFactorAuthenticationSend.vue';
import MultiFactorAuthenticationVerify from '@/company-portal/pages/MultiFactorAuthenticationVerify.vue';
import CompanyPortalLayout from '@/layouts/CompanyPortalLayout.vue';
import Avatar from 'primevue/avatar';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import ClHeader from './Navigation/ClHeader.vue';
import CompanyNavigation from './Navigation/top-nav/CompanyNavigation.vue';
import EmployeeNavigation from './Navigation/top-nav/EmployeeNavigation.vue';
import ShadowButton from './Navigation/top-nav/ShadowButton.vue';
import ShadowWarning from './Navigation/top-nav/ShadowWarning.vue';
import AccountActions from './PageSections/AccountActions.vue';

export const allComponents = {
    ClHeader,
    CompanyPortalLayout,
    EmployeeNavigation,
    CompanyNavigation,
    AccountActions,
    Avatar,
    Button,
    Menu,
    Menubar,
    ShadowButton,
    ShadowWarning,
    MultiFactorAuthenticationSend,
    MultiFactorAuthenticationVerify,
};
