export function formatMoney(amount: number, currency = 'USD') {
    if (!amount && amount !== 0) return;
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        signDisplay: 'never',
    });
    return formatter.format(amount);
}
