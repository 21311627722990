import renderComponent, {createElement} from "@/components/Plugins/RenderComponent";

export default async function (el, binding) {
    if ((binding.value || typeof binding.value === 'undefined') && !el.loadingComponent) {
        if (!el.classList.contains('relative')) {
            el.classList.add('relative');
        }

        el.loadingComponent = renderComponent({
            el: el.appendChild(createElement('div', {class: 'loading-wrapper'})),
            component: (await import('@/components/Components/BlockLoader.vue')).default,
            appContext: binding.instance,
        })
    } else if (el.querySelector(':scope > .loading-wrapper')) {
        if (el.loadingComponent) {
            el.loadingComponent();
            el.loadingComponent = undefined;
        }

        el.querySelector(':scope > .loading-wrapper').remove()
    }
}
