import { SessionUser, SessionUserMeta } from '@/interfaces/SessionUser';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useCurrentUserStore = defineStore(
    'currentUser',
    () => {
        const currentUser = ref<SessionUser>();
        const currentUserMeta = ref<SessionUserMeta>({
            hasEmployeeRole: false,
            supportAccountId: 0,
            userAccountId: 0,
            csrfToken: '',
        });

        return {
            currentUser,
            currentUserMeta,
            setCurrentUser(user: SessionUser) {
                currentUser.value = user;
            },
            setCurrentUserMeta(meta: SessionUserMeta) {
                currentUserMeta.value = meta;
            },
        };
    },
    {
        persist: true,
    },
);
