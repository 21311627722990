import { PrimeVuePTOptions } from 'primevue/config';
// import { MenuContext, MenuProps, MenuState } from 'primevue/menu';

export const MenuDesign = {
    menu: {
        root: {
            class: 'rounded bg-white shadow-slate-400 border-1 shadow-md	border-slate-200',
        },
        submenuHeader: { class: 'text-surface-900' },
        menuitem: 'p-1',
        action: 'p-3 w-full cursor-pointer hover:bg-slate-200 no-underline',
        content: 'bg-white',
    },
} as Pick<PrimeVuePTOptions, 'menu'>;
