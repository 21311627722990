<script lang="ts" setup>
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import type { MenuItem } from 'primevue/menuitem';
import { computed, ref } from 'vue';
const menu = ref();
const toggleAccountOptions = (event: MouseEvent) => {
    menu.value.toggle(event);
};
const props = withDefaults(
    defineProps<{
        allowImpersonate: boolean;
        impersonateUrl: string;
        accountId: string;
    }>(),
    { allowImpersonate: true },
);

const companyAccountActionsOptions = computed(() => {
    const items: MenuItem[] = [
        {
            id: 'enable',
            label: 'Enable Payments',
            url: `/spa/account/${props.accountId}/payment-settings`,
        },
    ];
    if (props.allowImpersonate) {
        items.push({
            id: 'shadow',
            label: 'Shadow Account',
            url: props.impersonateUrl,
        });
    }
    items.push({
        id: 'deactivate',
        label: 'Deactivate Account',
        danger: true,
        command: () => {
            // eslint-disable-next-line no-undef
            $('#deactivateAccountModal').modal();
        },
    });
    return [{ items }];
});
</script>
<template>
    <Button
        outlined
        @click="toggleAccountOptions"
    >
        <template #default>
            <span class="text-base font-medium">Actions</span>
            <font-awesome-icon
                icon="fa-chevron-down"
                class="border-w ml-1"
            ></font-awesome-icon>
        </template>
    </Button>
    <Menu
        ref="menu"
        :model="companyAccountActionsOptions"
        :popup="true"
    >
        <template #item="{ item, props }">
            <a
                class="flex"
                style="text-decoration: none"
                v-bind="props.action"
                :href="item.url"
            >
                <span :class="item.icon" />
                <span
                    :class="{ 'text-red-600': item.danger }"
                    style="text-decoration: none"
                    >{{ item.label }}</span
                >
            </a>
        </template>
    </Menu>
</template>
