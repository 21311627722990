import dayjs from 'dayjs';

export const API_DATE_FORMAT = 'YYYY-MM-DD hh:mm:ss';
export const DAY_MONTH_YEAR_FORMAT = 'MM/DD/YYYY';

export function formatDate(date: Date | string, format: string = DAY_MONTH_YEAR_FORMAT, fallback = '') {
    if (!date) return fallback;
    const dateParsed = dayjs(date);
    if (dateParsed.isValid()) {
        return dateParsed.format(format);
    }
    return fallback;
}
